// COMPONENTS
import React, { useState } from 'react'
import Layout from 'layout/Layout'
import SEO from 'layout/SEO'
// SECTIONS
import Hero from 'LandingPage/Hero'
import SearchBar from 'components/Search/SearchBar'
import CardListing from 'components/Item/CardListing'
// import Banner from 'LandingPage/Banner'
// import Socials from 'LandingPage/Socials'
// USER SIGNUP/CONTACT
// import SignUp from 'components/SignUp'
import SimpleSignUp from 'components/SignUp/simple'
// import MessageDrop from 'components/SignUp/MessageDrop'
// LOGIC / API
import { tsFetch } from 'apis/TSFetch'
import hardwareswapAPI from 'apis/Hardwareswap'
// NPM
import { format } from 'timeago.js'


// Flexible landing search page template
const SearchPage = ({ content }) => {
  const {
    layout,
    seo,
    hero,
    search,
    // posts,
    stickySignup,
  } = content

  const [posts, updatePosts] = useState(null);
  // Fetch user information on load
  // useEffect(() => {
  //   updateUser(getUser());
  // }, [])

  const searchHardwareswap = async (query) => {
    console.log(`r/hardwareswap API query: `+ query.query)
    const params = {
      q: query.query,
      p: 't',
      f: 'selling',
      c: 'USA',
      s: 'CA',
      t: 'week',
      l: 50,
      o: 'new',
    }
    const searchResults = await tsFetch(hardwareswapAPI, 'hardwareswap', 'searchPosts', null, params)
    if (searchResults && searchResults.length > 0){
      const formattedResults = formatPosts(searchResults)
      updatePosts(formattedResults)
    }
    return searchResults
  }

  // Post component format
  // const { title, subtitle, date, path, image, meta } = content
  const formatPosts = (posts) => {
    // console.log(data)
    const formatted = posts.map( post => {
      let { PROCESSED, created, url, author } = post
      // let { titleBlocks, location, zipcodes, prices, flair, images, urls } = PROCESSED
      let { titleBlocks, location, prices, images } = PROCESSED
      let date = format(parseInt(created) * 1000)
      let price = (prices && ( prices.minPrice !== 0 && prices.maxPrice !== 0)) ? `$${prices.minPrice} - $${prices.maxPrice} ` : 'no price'
      let loc = location ? `${location.parsedCountry} - ${location.parsedState}` : ''
      // console.log(post)
      return {
        title: `[W] ${titleBlocks.wantBlk}`,
        subtitle: `[H] ${titleBlocks.haveBlk}`,
        date,
        path: url,
        image: ( images && images.length > 0) ? images[0] : null,
        meta: [ 
          {text: date}, 
          {text: price}, 
          {text: loc}, 
          {text: author.name, url: `https://reddit.com/u/${author.name}`}
        ]
      }
    })
    return { postCount: posts.length, formatted}
  }
  // let p = formatPosts(data)
  // const posts = formatPosts(data)
  // console.log(posts)

  return (
    <Layout header={layout.header} stickyFooter={stickySignup && <SimpleSignUp content={stickySignup} />}>
      <SEO title={seo.title} image={seo.imageUrl} />
      { hero && <Hero content={hero} /> }
      { search && <SearchBar content={search} searchCB={searchHardwareswap} /> }
      { (posts && posts.postCount > 0) && <CardListing content={posts} /> }
    </Layout>
  )
}

export default SearchPage
