// COMPONENTS
import React from 'react'
import SearchPage from 'templates/SearchPage'
// CONTENT
import content from 'content/hardwareswap.yml'

// Hardwareswap page
const HardwareSwapPage = () => {
  // return <LandingPage content={content} />
  return <SearchPage content={content} />
}

export default HardwareSwapPage
