const uri = 'https://l7ar5cqse9.execute-api.us-east-1.amazonaws.com/dev/hardwareswap/'
export default {
  reqHeaders: {
    'Content-Type': 'application/json',
  },
  // Hardwareswap API
  /**
  endpoints:
    GET - https://l7ar5cqse9.execute-api.us-east-1.amazonaws.com/dev/hardwareswap/fetchPosts
    GET - https://l7ar5cqse9.execute-api.us-east-1.amazonaws.com/dev/hardwareswap/searchPosts
  */
  hardwareswap: {
    fetchPosts: {
      endpoint: `${uri}fetchPosts`,
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    searchPosts: {
      endpoint: `${uri}searchPosts`,
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
  },
}
